<template>
  <div class="section7">
    <v-container fluid class="py-0 px-0">
      <v-row class="pb-md-10">
        <v-col
          data-aos="fade-right"
          cols="9"
          md="5"
          offset-md="1"
          class="main-title px-5 px-md-0 py-0 d-flex align-center"
        >
          NUESTROS CLIENTES
        </v-col>
        <v-col cols="3" md="6" class="grilla-container px-0">
          <img
            class="d-block d-md-none"
            :src="require('../assets/grilla-7-mob.png')"
          />
        </v-col>
      </v-row>
      <v-row align-content-md="center" class="mt-md-0 logos-container">
        <v-col
          data-aos="zoom-out"
          cols="6"
          md="2"
          offset-md="1"
          class="icon-animation px-0 pt-12 d-flex justify-center align-center  py-md-0 justify-md-start"
        >
          <img :src="require('../assets/img-logo-bisa.png')" />
        </v-col>
        <v-col
          data-aos="zoom-out"
          cols="6"
          md="2"
          class="px-0 pt-12 d-flex justify-start align-center  py-md-0 justify-md-start"
        >
          <img :src="require('../assets/img-logo-epcmaster.png')" />
        </v-col>
        <v-col
          data-aos="zoom-out"
          cols="6"
          md="2"
          class="px- py-8 d-flex justify-center align-center  py-md-0 justify-md-start"
        >
          <img :src="require('../assets/img-logo-tecnofast.png')" />
        </v-col>

        <v-col
          data-aos="zoom-out"
          cols="6"
          md="2"
          class="px-0 py-8 d-flex justify-start align-center  py-md-0 justify-md-start"
        >
          <img :src="require('../assets/img-logo-worley.png')" />
        </v-col>
        <v-col
          data-aos="zoom-out"
          cols="12"
          md="2"
          class="px-0 d-flex justify-center align-center  py-md-0 justify-md-start"
        >
          <img :src="require('../assets/img-logo-inmobiliaria.png')" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  methods: {},
  data () {
    return {}
  }
}
</script>

<style lang="scss">
.section7 {
  overflow-x: hidden;
  min-height: 480px;
  background: #003da2;
  padding: 40px 0 40px 20px;
  @supports (
      (-webkit-text-stroke-color: #dfe1e5) and
        (-webkit-text-fill-color: transparent)
    )
    or
    ((-moz-text-stroke-color: #dfe1e5) and (-moz-text-fill-color: transparent)) {
    .main-title {
      font-style: normal;
      font-weight: 800;
      font-size: 40px;
      line-height: 40px;
      opacity: 0.5;
      color: transparent;
      -moz-text-fill-color: transparent;
      -webkit-text-fill-color: transparent;
      -moz-text-stroke-color: #dfe1e5;
      -webkit-text-stroke-color: #dfe1e5;
      -moz-text-stroke-width: 1px;
      -webkit-text-stroke-width: 1px;
    }
  }
}
@media (min-width: 960px) {
  .section7 {
    overflow-x: hidden;
    min-height: 480px;
    background: #003da2;
    padding: 40px 0 40px 0px;
    @supports (
        (-webkit-text-stroke-color: #dfe1e5) and
          (-webkit-text-fill-color: transparent)
      )
      or
      (
        (-moz-text-stroke-color: #dfe1e5) and
          (-moz-text-fill-color: transparent)
      ) {
      .main-title {
        font-style: normal;
        font-weight: 800;
        font-size: 80px;
        line-height: 80px;
        opacity: 0.5;
        color: transparent;
        -moz-text-fill-color: transparent;
        -webkit-text-fill-color: transparent;
        -moz-text-stroke-color: #dfe1e5;
        -webkit-text-stroke-color: #dfe1e5;
        -moz-text-stroke-width: 1px;
        -webkit-text-stroke-width: 1px;
      }
      .grilla-container {
        background-image: url('../assets/grilla7.png');
        background-repeat: repeat;
      }
      .logos-container {
        height: 200px;
        img {
          margin-bottom: 0px;
          transition: margin-bottom 0.3s ease-in-out;
          &:hover {
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}
</style>
