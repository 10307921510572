import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './firebase'
import vuetify from './plugins/vuetify';
import AOS from 'aos'
import 'aos/dist/aos.css'
import checkView from 'vue-check-view'
import VueSmoothScroll from 'vue2-smooth-scroll'

Vue.use(VueSmoothScroll)
Vue.use(checkView)
Vue.config.productionTip = false
Vue.mixin({
  methods: {
    growSquare: function (el) {
      if(window.innerWidth>=960){
        el.target.element.style.width = "85px"
      }
    },
  },
})

Vue.prototype.$openURL=  (url)=>{
  window.open(url,"_blank")
}

new Vue({
  router,
  created(){
    AOS.init({
      once: true
    })
  },
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
