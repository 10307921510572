<template>
  <div style="height:100%;" class="d-flex align-end ml-0 ml-md-0">
    <v-progress-linear
      color="#003DA2"
      rounded
      v-model="value1"
      class="progress-width"
    ></v-progress-linear>
    <v-progress-linear
      color="#003DA2"
      rounded
      v-model="value2"
      class="ml-3 progress-width"
    ></v-progress-linear>
    <v-progress-linear
      color="#003DA2"
      rounded
      v-model="value3"
      class="ml-3 progress-width"
    ></v-progress-linear>
  </div>
</template>

<script>
export default {
  data () {
    return {
      current: 0,
      incrementPercentage: 5,
      timeToChange: 3000,
      value1: 0,
      value2: 0,
      value3: 0
    }
  },
  mounted () {
    this.initAnimation()
  },
  methods: {
    forceChange (newCurrent) {
      if (newCurrent == 0) {
        this.value1 = 0
        this.value2 = 0
        this.value3 = 0
      }
      if (newCurrent == 1) {
        this.value1 = 100
        this.value2 = 0
        this.value3 = 0
      }
      if (newCurrent == 2) {
        this.value1 = 100
        this.value2 = 100
        this.value3 = 0
      }
      this.current = newCurrent
      this.$emit('currentIndex', this.current)
    },
    initAnimation () {
      setInterval(() => {
        let number = this.current
        if (number == 0) {
          this.value1 = this.value1 + this.incrementPercentage
          if (this.value1 >= 100) {
            this.current++
            this.$emit('currentIndex', this.current)
          }
        } else if (number == 1) {
          this.value2 = this.value2 + this.incrementPercentage
          if (this.value2 >= 100) {
            this.current++
            this.$emit('currentIndex', this.current)
          }
        } else if (number == 2) {
          this.value3 = this.value3 + this.incrementPercentage
          if (this.value3 >= 100) {
            this.current = 0
            this.$emit('currentIndex', this.current)
            this.value1 = 0
            this.value2 = 0
            this.value3 = 0
          }
        }
      }, this.timeToChange / this.incrementPercentage / 3.5)
    }
  }
}
</script>

<style>
.progress-width {
  width: 60px;
}
@media (min-width: 960px) {
  .progress-width {
    width: 120px !important;
  }
}
</style>
