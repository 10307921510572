import firebase from "firebase/app";
//import "firebase/analytics";
import "firebase/functions";
var firebaseConfig = {
  apiKey: "AIzaSyCoDTCCCA4FTcMyV7tgTqlwhVClgb4ICK4",
  authDomain: "lima-express-cargo.firebaseapp.com",
  databaseURL: "https://lima-express-cargo.firebaseio.com",
  projectId: "lima-express-cargo",
  storageBucket: "lima-express-cargo.appspot.com",
  messagingSenderId: "642121565830",
  appId: "1:642121565830:web:4497e9d6c5a621d819f307",
  measurementId: "G-MVWC9SSMLS",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//firebase.functions().useFunctionsEmulator("http://localhost:5001")
//firebase.analytics();
