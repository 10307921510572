<template>
  <div class="section-footer">
    <v-row class="mt-6 mt-md-0">
      <v-col
        class="d-flex justify-center justify-md-start align-md-start"
        cols="12"
        md="4"
        offset-md="1"
      >
        <img :src="require('../assets/img-logo-arci-footer.svg')" />
      </v-col>
      <v-col cols="12" md="3">
        <div class="main-text text-md-left">Síguenos en:</div>
        <div class="d-flex justify-center mt-5 flex-md-column list-social-networks">
          <div
            class="d-flex align-center mb-md-5 hover"
            @click="$openURL('https://www.facebook.com/Arcistudiope')"
          >
            <img
              height="20"
              width="20"
              :src="require('../assets/ic-facebook.png')"
            />
            <span
              style="color: #F1F3F7;font-size:12px;"
              class="d-none d-md-block ml-3"
              >Facebook</span
            >
          </div>
          <div
            class="d-flex align-center mb-md-5 hover"
            @click="
              $openURL('https://www.linkedin.com/company/70474789/admin/')
            "
          >
            <img
              height="20"
              width="20"
              class="ml-10 ml-md-0"
              :src="require('../assets/ic-linkedin.png')"
            />
            <span
              style="color: #F1F3F7;font-size:12px;"
              class="d-none d-md-block ml-3"
              >Linkedin</span
            >
          </div>
          <div
            class="d-flex align-center mb-md-5 hover"
            @click="$openURL('https://www.instagram.com/arcistudio/')"
          >
            <img
              height="20"
              width="20"
              class="ml-10 ml-md-0"
              :src="require('../assets/ic-instagram.png')"
            />
            <span
              style="color: #F1F3F7;font-size:12px;"
              class="d-none d-md-block ml-3"
              >Instagram</span
            >
          </div>
        </div>
      </v-col>
      <v-col cols="12" class="mt-7 mt-md-0 text-md-left " md="2">
        <div class="main-text text-md-left">Contáctanos en:</div>
        <div class="d-flex  justify-md-start justify-center mt-4">
          <v-icon color="white"> mdi-email-outline </v-icon
          ><span class="subtext ml-3">contacto@arcistudio.com</span>
        </div>
        <div
          @click="$openURL('https://wa.me/51981105731')"
          class="d-flex justify-center justify-md-start mt-4 hover "
        >
          <v-icon color="white"> mdi-whatsapp </v-icon
          ><span class="subtext ml-3">(+51) 981 105 731</span>
        </div>
      </v-col>
      <v-col cols="12" class="mt-7 mt-md-0 py-0" md="4" offset-md="1">
        <div class="main-text text-md-left">© 2020 ARCI STUDIO</div>
        <div class="subtext text-md-left">
          Diseñado y desarrollado por
          <span
            @click="$openURL('https://umatec.pe')"
            class="hover text-decoration-underline"
            >UMATEC</span
          >
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
.section-footer {
  overflow-x: hidden;
  height: 600px;
  background: #000000;
  padding: 40px;
  .main-text {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    text-align: center;
  }
  .subtext {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: #f1f3f7;
    text-align: center;
  }
}
@media (min-width: 960px) {
  .section-footer {
    overflow-x: hidden;
    overflow: hidden;
    height: 330px;
  }
  .list-social-networks{
    span{
      border-bottom: 1px solid transparent;
      transition: border-bottom .2s ease-in-out;
      &:hover{
        border-bottom: 1px solid white;
      }
    }
  }
}
</style>
