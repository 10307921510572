<template>
  <div class="section5">
    <v-container fluid class="py-0 px-0">
      <div class="d-flex align-center mb-8 mb-md-15">
        <div v-view.once="growSquare" class="blue-rectangle"></div>
        <span class="blue-text-title">¿QUIÉNES SOMOS?</span>
      </div>
      <div style="height:30px;" class="d-none d-md-block"></div>
      <v-row class="cyan-container px-md-5 px-lg- " justify-md="center">
        <v-col
          data-aos="fade-right"
          cols="12"
          md="5"
          lg="4"
          class="image-container1 mb-10"
        >
          <div class="image-wrap">
            <img :src="require('../assets/jannet.jpg')" />
          </div>
        </v-col>
        <v-col
          data-aos="fade-left"
          cols="12"
          md="6"
          lg="5"
          class="mt-15 mt-md-0 p-relative d-block"
        >
          <div class="name px-md-15 mt-3 mt-md-1">Jannet Fernández Loayza</div>
          <div class="job px-md-15">COFUNDADORA</div>
          <div class="bio container1 fill-height">
            Arquitecta con más de 14 años de experiencia en diseño de
            Edificaciones. Graduada de la Universidad Nacional Federico
            Villareal, con Maestría en Dirección de la Construcción en la
            Universidad Peruana de Ciencias Aplicadas y especialización en BIM
            Management, Lean Construction otorgado por la Fundación Politécnica
            de Cataluña (España). Especialista en diseñar espacios adecuados que
            generen sensaciones de bienestar al usuario final tanto en forma,
            función y estética.
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          data-aos="fade-right"
          cols="12"
          class="yellow-text quienes-somos py-0"
        >
          ¿QUIÉNES SOMOS?
        </v-col>
      </v-row>
      <v-row
        justify-md="center"
        class="cyan-container px-md-5 px-lg-15 margin-container-2"
      >
        <v-col
          cols="12"
          md="5"
          data-aos="fade-right"
          class="image-container2 mb-10 d-block d-md-none"
        >
          <img :src="require('../assets/eddie.jpg')" />
        </v-col>
        <v-col
          data-aos="fade-right"
          cols="12"
          md="6"
          lg="5"
          offset-lg="1"
          class="mt-15 mt-md-0 p-relative"
        >
          <div class="name text-left mx-0 mt-4 mt-md-0 px-md-10">
            Eddy Anthonny Marañón Medina
          </div>
          <div class="job text-left mx-0 px-md-10">COFUNDADOR</div>
          <div class="bio text-left container2 fill-height">
            Ingeniero Civil con más de 13 años de experiencia en Diseño de
            Estructuras. Graduado de la Universidad Nacional de Ingeniería, con
            especialización en Diseño Estructural en la Pontificia Universidad
            Católica del Perú (2016) y en la Universidad Peruana de Ciencias
            Aplicadas (2010). Experto en análisis del comportamiento y diseño
            Sismorresistente de Estructuras con diferentes tipos de materiales
            como Concreto Armado, Albañilería y Acero Estructural, siguiendo los
            lineamientos de la normativa peruana e internacional.
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          lg="4"
          data-aos="fade-left"
          class="image-container2 mb-10 d-none d-md-block"
        >
          <div class="image-wrap">
            <img :src="require('../assets/eddie.jpg')" />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  methods: {},
  data () {
    return {}
  }
}
</script>

<style lang="scss">
.section5 {
  overflow-x: hidden;
  margin-top: 120px;
  overflow-y: hidden;
  .quienes-somos {
    font-style: normal;
    font-weight: 800;
    font-size: 64px;
    line-height: 64px;
    text-align: center;
  }
  .cyan-container {
    background: #eff3fb;
    min-height: 630px;
    padding: 20px;
    margin-top: 130px;
    position: relative;
    .image-container1 {
      height: 80px;
      img {
        width: 235px;
        height: 274px;
        position: absolute;
        top: -80px;
      }
    }
    .image-container2 {
      height: 80px;
      img {
        width: 235px;
        height: 274px;
        position: absolute;
        top: -80px;
        right: 30px;
      }
    }
    .name {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      text-align: right;
      color: #003da2;
      margin-bottom: 10px;
    }
    .job {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      text-align: right;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #000000;
      margin-bottom: 20px;
    }
    .bio {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 32px;
      color: #000000;
    }
  }
  .margin-container-2 {
    margin-top: 80px;
  }
}
@media (min-width: 960px) {
  .section5 {
    min-height: 1300px !important;

    .quienes-somos {
      font-size: 100px;
      line-height: 120px;
      text-align: center;
      margin-bottom: 90px;
    }
    .container1 {
      padding: 20px 100px 20px 100px !important;
      background: #eff3fb !important;
    }
    .container2 {
      padding: 20px 100px 20px 40px !important;
      background: #eff3fb !important;
    }
    .cyan-container {
      background: transparent;
      min-height: 410px;

      padding: 20px 100px 0 70px;
      margin-top: 130px;
      position: relative;
      .image-container1 {
        height: 80px;
        width: 100%;
        position: relative;
        z-index: 10;
        .image-wrap {
          width: 100%;
          height: 360px;
          //position: absolute;
          //top: -180px;
          right: -80px;
          img {
            position: absolute;
            width: auto;
            right: -50px;
            height: 360px;
          }
        }
      }
      .image-container2 {
        height: 80px;
        width: 100%;
        position: relative;
        z-index: 10;
        .image-wrap {
          width: 100%;
          height: 360px;
          //position: absolute;
          //top: -180px;

          //left: -80px;
          img {
            position: absolute;
            width: auto;
            left: -60px;
            height: 360px;
          }
        }
      }
      .name {
        text-align: left;
        margin-left: 40px;
        position: absolute;
        top: -70px;
      }
      .job {
        text-align: left;
        margin-left: 40px;
        position: absolute;
        top: -30px;
      }
      .bio {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 32px;
        color: #000000;
      }
    }
    .margin-container-2 {
      margin-top: 80px;
    }
  }
}
</style>
