<template>
  <div class="section2">
    <v-container fluid class="py-0 px-0">
      <div class="d-flex align-center mb-8">
        <div v-view.once="growSquare" class="blue-rectangle"></div>
        <span class="blue-text-title">¿QUÉ ES ARCI?</span>
      </div>
      <v-row>
        <v-col cols="12" md="6" class="cyan-container p-relative">
          <div class="first-text">
            Somos especialistas en proyectos de Edificaciones como Viviendas,
            Casas de Playa, Casas de Campo, Edificios Multifamiliares, Edificios
            de Oficinas entre otros.
          </div>
          <div class="second-text">
            ARCI es un estudio conformado por Arquitectos e Ingenieros dedicados
            al desarrollo de proyectos multidisciplinarios.
          </div>
          <div
            data-aos="fade-left"
            class="floating-text yellow-text d-block d-md-none"
          >
            ¿QUÉ ES ARCI?
          </div>
        </v-col>
        <v-col cols="12" md="5" class="px-0 py-0 p-relative">
          <div
            data-aos="fade-left"
            class="floating-text2 yellow-text d-none d-md-block"
          >
            ¿QUÉ ES ARCI?
          </div>
          <img :src="require('../assets/que-es.jpg')" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import GroupProgress from './progress/GroupProgress.vue'
export default {
  components: { GroupProgress },
  methods: {},
  data () {
    return {}
  }
}
</script>

<style lang="scss">
.section2 {
  overflow-x: hidden;
  padding-top: 120px;
  background-color: white ;
  img {
    width: 100% !important;
  }
  .cyan-container {
    background: #eff3fb;
    padding: 40px 20px 0px 20px;
    height: 420px;

    .first-text {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 32px;
      color: #003da2;
    }
    .second-text {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 32px;
      margin-top: 20px;
      color: #000000;
    }
  }
  .floating-text {
    font-style: normal;
    font-weight: 800;
    font-size: 64px;
    text-align: right;
    line-height: 64px;
    position: absolute;
    bottom: -60px;
    right: 15px;
    z-index: 1000;
  }
}

@media (min-width: 960px) {
  .section2 {
    overflow-x: hidden;
    margin-top: 120px;
    min-height: 700px;
    padding-top: 50px;
    background-color: transparent;
    img {
      width: 100%;
      height: 528px;
    }
    .cyan-container {
      background: linear-gradient(to right, #eff3fb 50%, transparent 50%);
      padding: 0;
      height: 528px;
      padding: 120px 200px 120px 200px;

      .first-text {
        font-size: 16px;
        line-height: 40px;
        width: 349px;
      }
      .second-text {
        font-size: 16px;
        line-height: 40px;
        width: 349px;
      }
    }
    .floating-text2 {
      font-style: normal;
      font-weight: 800;
      font-size: 80px;
      line-height: 80px;
      width: 321px;
      text-align: right;
      position: absolute;
      top: -80px;
      right: 0;
      z-index: 100;
    }
  }
}
</style>
