<template>
  <div class="section4" id="form">
    <img
      class="d-block d-md-none"
      :src="require('../assets/top-form-image.png')"
      width="100%"
      height="60px"
    />
    <v-row>
      <v-col class="d-none d-md-block patron-container" md="2"> </v-col>
      <v-col cols="12" md="10" class="pb-0">
        <v-row justify-md="center">
          <v-col cols="12" md="10" class="form-title py-0 px-9"
            >CONTÁCTANOS</v-col
          >
        </v-row>
        <v-form v-model="valido">
          <v-row justify-md="center">
            <v-col cols="12" md="5" class="px-0 py-0">
              <v-col cols="12" class="mt-8 py-0 px-9">
                <div class="form-label">NOMBRES Y APELLIDOS</div>
                <v-text-field
                  :rules="rules.fullname"
                  flat
                  color="rgba(255, 255, 255, 0.1)"
                  dark
                  class="pt-1"
                  hide-details
                  @keypress="isNumber($event)"
                  v-model="form.fullname"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mt-4 py-0 px-9">
                <div class="form-label">TELÉFONO</div>
                <v-text-field
                  flat
                  :rules="rules.phone"
                  color="rgba(255, 255, 255, 0.1)"
                  dark
                  class="pt-1"
                  hide-details
                  v-model="form.phone"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mt-4 py-0 px-9">
                <div class="form-label">E-MAIL</div>
                <v-text-field
                  flat
                  color="rgba(255, 255, 255, 0.1)"
                  dark
                  :rules="rules.email"
                  class="pt-1"
                  type="email"
                  
                  v-model="form.email"
                ></v-text-field>
              </v-col>
            </v-col>
            <v-col cols="12" md="5" class="px-0 py-0 mt-md-4"
              ><v-col cols="12" class="mt-4 py-0 px-9">
                <div class="form-label">EMPRESA</div>
                <v-text-field
                  flat
                  :rules="rules.business"
                  color="rgba(255, 255, 255, 0.1)"
                  dark
                  class="pt-1"
                  hide-details
                  v-model="form.business"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mt-4 py-0 px-9">
                <div class="form-label">PROYECTO</div>
                <v-textarea
                  flat
                  :rules="rules.project"
                  color="rgba(255, 255, 255, 0.1)"
                  dark
                  rows="4"
                  class="pt-1"
                  hide-details
                  no-resize
                  v-model="form.project"
                ></v-textarea>
              </v-col>
            </v-col>
            <v-col cols="12" md="5" offset-md="5" class="mt-10 py-0 px-9">
              <div v-show="showError" class="font-weight-bold mb-3  red--text">
                Debe llenar todos los campos
              </div>
              <v-btn
                :class="{ disabledForm: !valido }"
                :loading="loading"
                @click="sendEmail"
                v-if="!sent"
                block
                color="#FFDD3B"
                large
                tile
                ><v-icon color="#003DA2"> mdi-email-outline </v-icon
                ><span class="inner-text-button">ENVIAR</span></v-btn
              >
              <div
                style="color:rgb(255, 221, 59);"
                v-else
                class="font-weight-normal text-center"
              >
                Mensaje enviado
              </div>
            </v-col>

            <v-col cols="12" class="mt-12 py-0 section-footer">
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  class="normal-text d-flex justify-center align-center px-0 py-0 pt-md-4"
                >
                  O escríbenos directamente mediate WhatsApp:
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                  class="px-3 pl-md-9 py-0 d-flex align-center justify-center"
                >
                  <v-btn
                    @click="$openURL('https://wa.me/51981105731')"
                    block
                    color="white"
                    outlined
                    large
                    ><v-icon color="white"> mdi-whatsapp </v-icon
                    ><span class="inner-text-button"
                      >(+51) 981 105 731</span
                    ></v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form></v-col
      >
    </v-row>
  </div>
</template>

<script>
import firebase from 'firebase/app'
export default {
  methods: {
    isNumber (evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        return true
      } else {
        evt.preventDefault()
      }
    },
    validateEmail (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    async sendEmail () {
      this.loading = true
      if (!this.valido) {
        this.showError = true
      } else {
        this.showError = false
        let result = await firebase.functions().httpsCallable('sendEmail')(
          this.form
        )
        console.log(result)
        this.sent = true
      }
      this.loading = false
    }
  },
  data () {
    return {
      rules: {
        fullname: [v => !!v || 'Ingrese su nombre completo.'],
        phone: [v => !!v || 'Ingrese su teléfono.'],
        email: [
          v => {
            if(this.validateEmail(v)){
              return true
            }
            return "El correo electrónico es inválido"
          }
        ],
        business: [v => !!v || 'Ingrese el nombre de su empresa.'],
        project: [v => !!v || 'Por favor, detallenos su proyecto.']
      },
      loading: false,
      sent: false,
      valido: false,
      showError: false,
      form: {
        fullname: '',
        phone: '',
        email: '',
        business: '',
        project: ''
      }
    }
  }
}
</script>

<style lang="scss">
.section4 {
  overflow-x: hidden;
  background: #003da2;
  min-height: 780px;

  @supports (
      (-webkit-text-stroke-color: #dfe1e5) and
        (-webkit-text-fill-color: transparent)
    )
    or
    ((-moz-text-stroke-color: #dfe1e5) and (-moz-text-fill-color: transparent)) {
    .form-title {
      font-style: normal;
      font-weight: 800;
      font-size: 40px;
      line-height: 40px;
      opacity: 0.5;
      margin-top: 60px;
      text-align: center;
      color: transparent;
      -moz-text-fill-color: transparent;
      -webkit-text-fill-color: transparent;
      -moz-text-stroke-color: #dfe1e5;
      -webkit-text-stroke-color: #dfe1e5;
      -moz-text-stroke-width: 1px;
      -webkit-text-stroke-width: 1px;
    }
  }
  .error--text {
    .v-messages__message{
      font-weight: bold;
    }
    
  }
  .form-label {
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.1em;
    color: #ffffff;
  }
  input {
    background: rgba(255, 255, 255, 0.1);
    height: 48px;
    padding-left: 10px !important;
  }
  textarea {
    background: rgba(255, 255, 255, 0.1);
    padding-left: 10px !important;
  }
  .v-input__slot:before {
    border: none !important;
    display: none !important;
  }
  .v-input__slot:after {
    border: none !important;
    display: none !important;
  }
  .v-btn {
    position: relative;
    i {
      position: absolute;
      left: 15%;
    }
    .inner-text-button {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.2em;
      color: #003da2;
    }
  }
  .section-footer {
    background: #000000;
    height: 125px;
    padding: 20px 40px 20px 40px !important;
    .normal-text {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
      text-align: center;
    }
    .v-btn {
      position: relative;
      margin-top: 20px;
      i {
        position: absolute;
        left: 15%;
      }
      .inner-text-button {
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.2em;
        color: #ffffff;
      }
    }
  }
}
@media (min-width: 960px) {
  .section4 {
    overflow-x: hidden;
    background: #003da2;
    min-height: 600px;
    .patron-container {
      background-image: url('../assets/patron-form-md.png');
      background-repeat: repeat;
    }
    @supports (
        (-webkit-text-stroke-color: #dfe1e5) and
          (-webkit-text-fill-color: transparent)
      )
      or
      (
        (-moz-text-stroke-color: #dfe1e5) and
          (-moz-text-fill-color: transparent)
      ) {
      .form-title {
        font-size: 80px;
        line-height: 120px;
        text-align: left;
        margin-top: 40px;
      }
    }
    .form-label {
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 0.1em;
      color: #ffffff;
    }
    input {
      background: rgba(255, 255, 255, 0.1);
      height: 48px;
    }
    textarea {
      background: rgba(255, 255, 255, 0.1);
    }
    .v-input__slot:before {
      border: none !important;
      display: none !important;
    }
    .v-input__slot:after {
      border: none !important;
      display: none !important;
    }
    .v-btn {
      position: relative;
      i {
        position: absolute;
        left: 15%;
      }
      .inner-text-button {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.2em;
        color: #003da2;
      }
    }
    .section-footer {
      background: #000000;
      height: 125px;
      padding: 20px 40px 20px 40px !important;
      .normal-text {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
        text-align: center;
      }
      .v-btn {
        position: relative;
        margin-top: 20px;
        i {
          position: absolute;
          left: 15%;
        }
        .inner-text-button {
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          letter-spacing: 0.2em;
          color: #ffffff;
        }
      }
    }
  }
}
.disabledForm {
  background: #000000 !important;
  opacity: 0.2;
  i {
    color: #ffffff !important;
  }
  .inner-text-button {
    color: #ffffff !important;
  }
}
</style>
