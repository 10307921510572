<template>
  <v-main>
    <section1 />
    <section2 />
    <section3 />
    <section4 />
    <section5 />
    <section6 />
    <section7 />
    <section8 />
    <section-footer/>
  </v-main>
</template>

<script>
import Section1 from '../components/Section1'
import Section2 from '../components/Section2'
import Section3 from '../components/Section3'
import Section4 from '../components/Section4'
import Section5 from '../components/Section5'
import Section6 from '../components/Section6'
import Section7 from '../components/Section7'
import Section8 from '../components/Section8'
import SectionFooter from '../components/SectionFooter'

export default {
  components: {
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Section6,
    Section7,
    Section8,
    SectionFooter
  }
}
</script>

<style></style>
