<template>
  <div class="section3">
    <v-container fluid class="py-0 px-0">
      <div class="d-flex align-center mb-8">
        <div v-view.once="growSquare" class="blue-rectangle"></div>
        <span class="blue-text-title">SERVICIOS</span>
      </div>
      <v-row>
        <v-col md="3" class="d-none d-md-flex flex-column tab-container">
          <div
            class="arci-tab"
            @click="serviceSelected = 0"
            :class="{ active: serviceSelected == 0 }"
          >
            <div>ARQUITECTURA</div>
            <div class="border"></div>
          </div>
          <div
            v-view.once="initAnimation"
            class="arci-tab"
            @click="serviceSelected = 1"
            :class="{ active: serviceSelected == 1 }"
          >
            <div>INGENIERÍA CIVIL</div>
            <div class="border"></div>
          </div>
          <div
            class="arci-tab"
            @click="serviceSelected = 2"
            :class="{ active: serviceSelected == 2 }"
          >
            <div>INSTALACIONES</div>
            <div class="border"></div>
          </div>
          <div
            class="arci-tab"
            @click="serviceSelected = 3"
            :class="{ active: serviceSelected == 3 }"
          >
            <div>OTROS</div>
            <div class="border"></div>
          </div>
        </v-col>
        <v-col cols="12" class="py-0 d-block d-md-none">
          <v-tabs v-model="serviceSelected" color="#003DA2" class="ml-0">
            <v-tab class="font-weight-bold">ARQUITECTURA</v-tab>
            <v-tab class="font-weight-bold">INGENIERÍA CIVIL</v-tab>
            <v-tab class="font-weight-bold">INSTALACIONES</v-tab>
            <v-tab class="font-weight-bold">OTROS</v-tab>
          </v-tabs>
        </v-col>
        <v-col cols="12" md="9" class="py-0">
          <v-tabs-items v-model="serviceSelected">
            <v-tab-item v-for="(serviceList, index) in services" :key="index">
              <div class="cyan-container">
                <ul>
                  <li v-for="(service, index) in serviceList" :key="index">
                    {{ service }}
                  </li>
                </ul>
              </div>
              <v-container class="py-0">
                <v-row>
                  <v-col cols="12" class="px-0 py-0 image-container">
                    <img width="100%" height="230px" :src="images[index][0]" />
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <v-col
          cols="12"
          md="9"
          offset-md="3"
          class="d-flex justify-start px-0 py-0 mt-1"
        >
          <div
            class="button-arrow d-flex justify-center align-center ml-1 ml-md-3"
            @click="decrementIndex"
          >
            <v-icon color="#003DA2">mdi-chevron-left</v-icon>
          </div>
          <div
            class="button-arrow d-flex justify-center align-center ml-1"
            @click="incrementIndex"
          >
            <v-icon color="#003DA2">mdi-chevron-right</v-icon>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  methods: {
    initAnimation () {
      let _this = this
      setInterval(() => {
        _this.incrementIndex()
      }, 15000)
    },
    incrementIndex () {
      if (this.serviceSelected == 3) {
        this.serviceSelected = 0
      } else {
        this.serviceSelected++
      }
    },
    decrementIndex () {
      if (this.serviceSelected == 0) {
        this.serviceSelected = 3
      } else {
        this.serviceSelected--
      }
    }
  },
  data () {
    return {
      serviceSelected: 0,
      services: [
        [
          'Diseño arquitectónico para expedientes técnicos de Obras nuevas y Ampliación ó Remodelación de viviendas.',
          'Diseño de interiores.',
          'Elaboración de Vistas 3D y recorridos virtuales.'
        ],
        [
          'Diseño de estructuras de obras de concreto armado, albañilería y acero estructural.',
          'Evaluación y reforzamiento de estructuras existentes.'
        ],
        [
          'Diseño de Instalaciones sanitarias y eléctricas para expedientes técnicos.'
        ],
        [
          'Consultoría para proyectos de minería e industria.',
          'Metrados y presupuestos.',
          'Trámites municipales.',          
        ]
      ],
      images: [
        [
          require('../assets/servicio-arquitectura.jpg'),
        ],
        [
          require('../assets/servicio-ingenieria.jpg'),
        ],
        [
          require('../assets/servicio-instalaciones.jpg'),
        ],
        [
          require('../assets/servicio-otros.jpg'),
        ]
      ]
    }
  }
}
</script>

<style lang="scss">
.section3 {
  overflow-x: hidden;
  margin-top: 120px;
  height: 800px;
  .v-slide-group__prev {
    display: none !important;
  }
  .image-container {
    height: auto;
    img {
      height: 150px;
    }
  }
  .cyan-container {
    background: #eff3fb;
    padding: 40px 20px 0px 20px;
    height: 300px;
    ul {
      list-style: none;
      li {
        margin: 0;
        padding-left: 1.5em;
        position: relative;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 32px;
        color: #000000;

        &:after {
          content: '';
          height: 0.8em;
          width: 0.8em;
          background-image: url('../assets/diamond.svg');
          display: block;
          position: absolute;
          //transform: rotate(45deg);
          top: 0.9em;
          left: 0;
        }
      }
    }
  }
  .button-arrow {
    background: #ffdd3b;
    width: 40px;
    height: 40px;
  }
}
@media (min-width: 960px) {
  .section3 {
    overflow-x: hidden;
    margin-top: 120px;
    height: 900px;
    .v-slide-group__prev {
      display: none !important;
    }
    .image-container {
      height: 320px;
      img {
        height: 320px;
      }
    }
    .tab-container {
      padding-left: 120px;
      .arci-tab {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        position: relative;
        /* identical to box height */

        color: #c5c9ce;
        margin-top: 40px;
        transition: color 0.2s ease-in-out;
        .border {
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          height: 2px;
          background: #c5c9ce;
          width: 0;
          position: absolute;
          bottom: 0;
          transition: width 0.2s ease-in-out;
          z-index: 100;
        }
        &.active {
          color: #003da2;
          .border {
            width: 120%;
            background: #003da2;
          }
        }
        &:hover {
          cursor: pointer;
        }
      }
    }

    .cyan-container {
      background: #eff3fb;
      padding: 40px 20px 0px 20px;
      height: 320px;
      ul {
        display: block;
        margin-left: auto;
        margin-right: auto;
        min-width: 520px !important;
        max-width: 520px !important;
        li {
          margin-top: 25px;
          font-size: 16px;
        }
      }
    }
    .button-arrow {
      background: #ffdd3b;
      width: 80px;
      height: 80px;
      i {
        font-size: 48px;
        font-weight: normal;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
