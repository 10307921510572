<template>
  <div class="section6">
    <v-container fluid class="py-0 px-0">
      <div class="d-flex align-center mb-8">
        <div v-view.once="growSquare" class="blue-rectangle"></div>
        <span  class="blue-text-title">¿QUÉ NOS DIFERENCIA?</span>
      </div>
      <v-row justify-md="center">
        <v-col cols="12" md="10" class="d-flex justify-end">
          <div data-aos="fade-right" class="main-title">
            ¿QUÉ NOS DIFERENCIA?
          </div>
        </v-col>
      </v-row>
      <v-row class="mx-4" justify-md="center">
        <v-col cols="12" data-aos="flip-right" md="3" v-for="(d, index) in diferencias" :key="index">
          <v-row>
            <v-col cols="3" md="12">
              <img class="card-icon" :src="d.image" />
            </v-col>
            <v-col cols="9" md="12">
              <div class="card-title">{{ d.title }}</div>
              <div class="card-text">{{ d.text }}</div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  methods: {},
  data () {
    return {
      diferencias: [
        {
          image: require('../assets/creatividad.png'),
          title: 'CREATIVIDAD',
          text:
            'Proyectos con identidad, pensados en satisfacer las necesidades de nuestros clientes.'
        },
        {
          image: require('../assets/experiencia.png'),
          title: 'EXPERIENCIA',
          text:
            'Mas de 13 años en participación de proyectos multidisciplinarios nos avalan.'
        },
        {
          image: require('../assets/confianza.png'),
          title: 'CONFIANZA',
          text:
            'Diseños realizados por especialistas que te acompañaran desde la concepción de tu idea hasta la culminación de tu proyecto.'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.section6 {
  overflow-x: hidden;
  margin-top: 120px;
  min-height: 800px;
  .main-title {
    font-style: normal;
    font-weight: 800;
    font-size: 48px;
    line-height: 48px;
    text-align: right;
    margin-top: 40px;
    color: #dce5f6;
  }
  .card-icon {
    width: 70px;
    height: 50px;
    margin-top: 13px;
  }
  .card-title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
  }
  .card-text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 32px;
    color: #000000;
  }
}
@media (min-width: 960px) {
  .section6 {
    overflow-x: hidden;
    margin-top: 120px;
    min-height: 700px;
    .main-title {
      font-size: 80px;
      line-height: 80px;
      width: 583px;
    }
    .card-icon {
      width: 90px;
      height: 70px;
      margin-top: 13px;
    }
    .card-title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
    }
    .card-text {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 32px;
      color: #000000;
    }
  }
}
</style>
