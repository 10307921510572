<template>
  <div class="section8">
    <v-container fluid class="py-0 px-0">
      <div class="d-flex align-center mb-8">
        <div v-view.once="growSquare" class="blue-rectangle"></div>
        <span class="blue-text-title">TRABAJOS REALIZADOS</span>
      </div>
      <v-row class="pt-10">
        <v-col cols="12" md="11" offset-md="1" class="p-relative">
          <div class="main-title ml-3 ml-md-0">
            TRABAJOS REALIZADOS
          </div>
        </v-col>
        <v-col cols="12" class="projects-main ">
          <v-row
            v-for="(project, index) in projects"
            :key="index"
            justify="center"
            class="project-container"
          >
            <v-col
              cols="11"
              md="8"
              class="px-0 project-info__container text-center text-md-left"
            >
              <div class="project-title">
                {{ project.title }}
              </div>
              <div class="prject-place">
                {{ project.place }}
              </div>
            </v-col>
            <v-col cols="12" md="10">
              <v-row class="d-flex d-md-none">
                <v-col class="px-0 py-0 d-flex px-5 pb-4" cols="12">
                  <img class="project-image-big" :src="project.images[0]" />
                </v-col>
                <v-col class="px-0 py-0 pl-5 pr-2" cols="6">
                  <v-img
                    height="150"
                    min-height="150"
                    max-height="150"
                    :src="project.images[1]"
                  />
                </v-col>
                <v-col class="px-0 py-0 pr-5 pl-2" cols="6">
                  <v-img
                    max-height="150"
                    height="150"
                    min-height="150"
                    :src="project.images[2]"
                  />
                </v-col>
              </v-row>
              <v-row justify="center" class="d-none d-md-flex">
                <v-col cols="10" class="d-flex">
                  <div class="d-flex">
                    <img class="project-image-big" :src="project.images[0]" />
                  </div>
                  <div
                    class="ml-4 d-flex flex-md-column justify-md-space-between"
                  >
                    <img class="project-image" :src="project.images[1]" />
                    <img class="project-image" :src="project.images[2]" />
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="11" class="pr-5 mx-md-0">
          <div class="my-projects-text">
            MÁS
            <div>PROYECTOS</div>
          </div>
        </v-col>
        <v-col cols="12">
          <v-row justify="center">
            <v-col cols="10" md="10" lg="8">
              <v-row justify="center">
                <v-col
                  cols="12"
                  md="4"
                  @click="selectImage(p)"
                  v-for="(p, index) in otherProjects"
                  :key="index"
                  ><v-img
                    contain
                    class="other-project-image"
                    height="225px"
                    min-height="200px"
                    width="100%"
                    :src="p.images[0]"
                  >
                    <div
                      @mouseover="overIndexProject = index"
                      @mouseleave="overIndexProject = -1"
                      class="first-other-project-container"
                    >
                      <div
                        @mouseover="overIndexProject = index"
                        @mouseleave="overIndexProject = -1"
                        class="helper-container"
                        :class="{ 'h-0': overIndexProject == index }"
                      ></div>
                      <div class="other-project-info">
                        <div class="other-project-title">{{ p.title }}</div>
                        <div class="other-project-type">{{ p.type }}</div>
                      </div>
                    </div>
                  </v-img>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      style="position:relative; box-shadow: none !important;"
      class="oli"
      v-model="viewImage"
      width="500px"
    >
      <v-card color="transparent">
        <v-card-title class="py-0">
          <v-toolbar flat color="transparent" class="px-0">
            <v-spacer />
            <v-icon @click="viewImage = false" color="white" x-large
              >mdi-close</v-icon
            >
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-carousel
            class="my-carrousel"
            hide-delimiters
            v-model="selectImageIndex"
          >
            <v-carousel-item
              v-for="(photo, index) in selectedProject.images"
              :key="index"
            >
              <img
                width="100%"
                height="100%"
                class="other-project-image"
                :src="photo"
              />
            </v-carousel-item>
          </v-carousel>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
  methods: {
    selectImage (project) {
      this.selectedProject = project
      this.selectImageIndex = 0
      this.viewImage = true
    }
  },
  components: { VueperSlides, VueperSlide },
  computed: {
    otherProjectsImages () {
      return []
    }
  },
  data () {
    return {
      viewImage: false,
      selectedProject: {
        images: []
      },
      overIndexProject: -1,
      selectImageIndex: -1,
      projects: [
        {
          title: 'Resort Chaclacayo',
          place: 'Chaclacayo',
          images: [
            require('../assets/resort_chaclacayo1.jpg'),
            require('../assets/resort_chaclacayo2.jpg'),
            require('../assets/resort_chaclacayo3.jpg')
          ]
        },
        {
          title: 'Edificio Multifamiliar Soria',
          place: 'Chaclacayo',
          images: [
            require('../assets/familia_1.jpg'),
            require('../assets/familia_3.jpg'),
            require('../assets/familia_2.jpg')
          ]
        }
      ],
      otherProjects: [
        {
          title: 'Modernización Almacén',
          type: 'DISEÑO ARQUITECTONICO ESTRUCTURAL',
          images: [require('../assets/Modernizacion Almacen Callao 1.jpg')]
        },
        {
          title: 'Edif. de Uso Mixto',
          type: 'DISEÑO ESTRUCTURAL',
          images: [
            require('../assets/Mixto 1.jpg'),
            require('../assets/Mixto 2.jpg')
          ]
        },
        {
          title: 'Edif.  Multifamiliar Betanzos 102',
          type: 'DISEÑO ESTRUCTURAL',
          images: [
            require('../assets/San Miguel 1.jpg'),
            require('../assets/San Miguel 2.jpg')
          ]
        },
        {
          title: 'Obras Civiles Exteriores Ancillary y Warehouse',
          type: 'DISEÑO ESTRUCTURAL',
          images: [
            require('../assets/Cuarto de Bombas.jpg'),
            require('../assets/Plataforma Elevada.jpg')
          ]
        },
        {
          title: 'Sub Estación Eléctrica Planta de Ventas',
          type: 'DISEÑO ESTRUCTURAL',
          images: [require('../assets/SE2.jpg')]
        },
        {
          title: 'Edif. de Filtrado Tambomayo',
          type: 'DISEÑO ESTRUCTURAL',
          images: [
            require('../assets/Filt 1.jpg'),
            require('../assets/Filt 2.jpg'),
            require('../assets/Filt 3.jpg')
          ]
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.v-dialog {
  box-shadow: none !important;
  .v-toolbar__content {
    padding: 0 !important;
  }
  img {
    height: 300px;
  }
}
.section8 {
  overflow-x: hidden;
  margin-top: 120px;
  min-height: 700px;

  @supports (
      (-webkit-text-stroke-color: #003da2) and
        (-webkit-text-fill-color: transparent)
    )
    or
    ((-moz-text-stroke-color: #003da2) and (-moz-text-fill-color: transparent)) {
    .main-title {
      font-style: normal;
      font-style: normal;
      font-weight: 800;
      font-size: 48px;
      line-height: 48px;
      opacity: 0.2;
      color: transparent;
      -moz-text-fill-color: transparent;
      -webkit-text-fill-color: transparent;
      -moz-text-stroke-color: #003da2;
      -webkit-text-stroke-color: #003da2;
      -moz-text-stroke-width: 3px;
      -webkit-text-stroke-width: 3px;
      position: absolute;
      top: -23px;
      z-index: 1000;
    }
  }
  .button-arrow {
    background: #ffdd3b;
    width: 40px;
    height: 40px;
  }
  .title-slide {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    margin: 0 0 10px 20px;
  }
  .place-slide {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    margin: 0 0 10px 20px;
  }
}
.h-0 {
  height: 0 !important;
}
.project-container {
  margin-top: 80px;
  .project-info__container {
    .project-title {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #003da2;
    }
    .project-place {
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 0.1em;
      color: #000000;
    }
  }
  .project-image {
    width: auto;
    height: auto;
    max-width: 100% !important;
    max-height: auto;
  }
  .project-image-big {
    width: auto;
    height: auto;
    max-width: 100% !important;
    max-height: auto;
  }
}
.my-projects-text {
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 80px;
  text-align: right;
  color: #dce5f6;
}
.v-dialog {
  box-shadow: none !important;
  .v-toolbar__content {
    padding: 0 !important;
  }
  img {
    height: auto;
  }
  height: auto;
}
.other-project-image {
  border: 1px solid #c5c9ce;
  &:hover {
    cursor: pointer;
  }
  .first-other-project-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    .helper-container {
      width: 100%;
      height: 0;
      transition: 0.1s height ease-in-out;
    }
    .other-project-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, #000000 -25%, rgba(0, 61, 162, 0) 50%);
      padding-left: 8px;
      padding-bottom: 8px;

      .other-project-title {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        color: #fdfeff;
      }
      .other-project-type {
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.1em;
        color: #000000;
      }
    }
  }
}
@media (min-width: 960px) {
  .section8 {
    overflow-x: hidden;
    margin-top: 120px;
    min-height: 700px;
    .main-title {
      width: 555px;
      font-size: 80px;
      line-height: 80px;
      z-index: 1000;
      top: -55px;
    }

    .button-arrow {
      background: #ffdd3b;
      width: 80px;
      height: 80px;
      i {
        font-size: 48px;
      }
    }
    .title-slide {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      margin: 0 0 10px 20px;
    }
    .place-slide {
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      margin: 0 0 10px 20px;
    }
    .my-projects-text {
      font-style: normal;
      font-weight: 800;
      font-size: 80px;
      line-height: 80px;
      text-align: right;
      color: #dce5f6;
    }
    .projects-main {
      margin-top: 120px;
    }
    .other-project-image {
      &:hover {
        cursor: pointer;
      }
      .first-other-project-container {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        .helper-container {
          width: 100%;
          height: 100%;
          transition: 0.1s height ease-in-out;
        }
        .other-project-info {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          width: 100%;
          height: 100%;
          background: linear-gradient(
            0deg,
            #000000 -25%,
            rgba(0, 61, 162, 0) 50%
          );
          padding-left: 8px;
          padding-bottom: 8px;

          .other-project-title {
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            color: #fdfeff;
          }
          .other-project-type {
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.1em;
            color: #000000;
          }
        }
      }
    }
    .project-container {
      margin-top: 80px;
      .project-info__container {
        .project-title {
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          color: #003da2;
        }
        .project-place {
          font-weight: normal;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.1em;
          color: #000000;
        }
      }
      .project-image {
        width: auto;
        height: auto;
        max-width: 100% !important;
        max-height: auto;
      }
      .project-image-big {
        width: auto;
        height: auto;
        max-width: 100% !important;
        max-height: auto;
      }
      .big-image-container {
        background-color: red;
      }
      .image-second-container {
        background-color: green;
      }
    }
  }
}
</style>
