<template>
  <v-app class="app">
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: 'App',

  data: () => ({
    //
  })
}
</script>
<style lang="scss">
.app {
  width: 100%;
  font-family: 'Montserrat', sans-serif !important;
}
.hover {
  &:hover {
    cursor: pointer;
  }
}

@supports (
    (-webkit-text-stroke-color: rgb(255, 221, 59)) and
      (-webkit-text-fill-color: transparent)
  )
  or
  (
    (-moz-text-stroke-color: rgb(255, 221, 59)) and
      (-moz-text-fill-color: transparent)
  ) {
  .yellow-text {
    -moz-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    -moz-text-stroke-color: rgb(255, 221, 59);
    -webkit-text-stroke-color: rgb(255, 221, 59);
    -moz-text-stroke-width: 2px;
    -webkit-text-stroke-width: 2px;
  }
}
/*
.yellow-text {
  color: transparent;
  text-shadow: rgb(255, 221, 59) 2px 0px 0px,
    rgb(255, 221, 59) 1.75517px 0.958851px 0px,
    rgb(255, 221, 59) 1.0806px 1.68294px 0px,
    rgb(255, 221, 59) 0.141474px 1.99499px 0px,
    rgb(255, 221, 59) -0.832294px 1.81859px 0px,
    rgb(255, 221, 59) -1.60229px 1.19694px 0px,
    rgb(255, 221, 59) -1.97998px 0.28224px 0px,
    rgb(255, 221, 59) -1.87291px -0.701566px 0px,
    rgb(255, 221, 59) -1.30729px -1.5136px 0px,
    rgb(255, 221, 59) -0.421592px -1.95506px 0px,
    rgb(255, 221, 59) 0.567324px -1.91785px 0px,
    rgb(255, 221, 59) 1.41734px -1.41108px 0px,
    rgb(255, 221, 59) 1.92034px -0.558831px 0px;
}*/
.visible {
  opacity: 1 !important;
}
.p-relative {
  position: relative;
}
.blue-rectangle {
  background: #003da2;
  width: 20px;
  height: 8px;
  transition: width 0.5s ease-in-out;
}
.blue-text-title {
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  color: #003da2;
  margin-left: 10px;
}
@media (min-width: 960px) {
  .blue-rectangle {
    height: 8px;
  }
  .blue-text-title {
    font-size: 12px;
  }
  .app {
  width: 100%;
  font-family: 'Montserrat', sans-serif !important;
}
}
</style>
