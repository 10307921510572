<template>
  <div class="section1">
    <v-container fluid class="py-0">
      <img  :src="require('../assets/scroll.png')" class="scrollDown d-none d-md-block" />
      <v-row>
        <v-col cols="6" md="9" class="py-0 pt-3 pl-5">
          <img
            class="d-block d-md-none"
            :src="require('../assets/img-logo-header-mobile.png')"
          />
          <img
            style="position:absolute;"
            class="d-none d-md-block ml-3"
            :src="require('../assets/img-logo-arci-header.svg')"
          />
        </v-col>
        <v-col
          cols="6"
          @click="scrollTo"
          md="3"
          class="yellow-container hover d-flex justify-center align-center"
        >
          <span>CONTÁCTANOS</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="main-title px-5 pt-15 d-block d-md-none ">
          TU PROYECTO ES IMPORTANTE PARA NOSOTROS
        </v-col>
        <v-col cols="12" class="pb-0 py-md-0">
          <v-row class="image-container p-relative">
            <div class="blur"></div>
            <img
              :src="image"
              v-for="(image, i) in images"
              :class="{ visible: imageIndex == i }"
              :key="i"
            />
            <div class="main-title d-none d-md-block">
              TU PROYECTO ES IMPORTANTE PARA NOSOTROS
            </div>
            <v-col cols="9" md="6" offset-md="3" class="container-white">
              <div class="white-background"></div>
              <span>
                Tenemos la experiencia necesaria para ayudarte a convertir tu
                idea en Arquitectura e Ingeniería cuidadosamente diseñada,
                acorde a tus necesidades y con estándares de alta calidad.
              </span>
            </v-col>
            <v-col cols="3"> </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="py-0">
          <v-row>
            <v-col cols="9" offset-md="3" md="6" class="py-0 px-0">
              <group-progress ref="progressGroup" @currentIndex="changeImage" />
            </v-col>
            <v-col cols="3" class="px-0 pt-1 pb-0 d-flex">
              <div
                @click="decrementImageIndex"
                class="button-arrow d-flex justify-center align-center"
              >
                <v-icon color="#FFDD3B">mdi-chevron-left</v-icon>
              </div>
              <div
                @click="incrementImageIndex"
                class="button-arrow d-flex justify-center align-center ml-2"
              >
                <v-icon color="#FFDD3B">mdi-chevron-right</v-icon>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import GroupProgress from './progress/GroupProgress.vue'
export default {
  components: { GroupProgress },
  methods: {
    scrollTo () {
      this.$smoothScroll({
        scrollTo: document.getElementById('form'),
        hash: '#form' // required if updateHistory is true
      })
    },
    changeImage (index) {
      this.imageIndex = index
    },
    incrementImageIndex () {
      if (this.imageIndex == 2) {
        this.imageIndex = 0
      } else {
        this.imageIndex++
      }
      this.$refs.progressGroup.forceChange(this.imageIndex)
    },
    decrementImageIndex () {
      if (this.imageIndex == 0) {
        this.imageIndex = 2
      } else {
        this.imageIndex--
      }
      this.$refs.progressGroup.forceChange(this.imageIndex)
    }
  },
  data () {
    return {
      imageIndex: 0,
      images: [
        require('../assets/hero01.jpg'),
        require('../assets/hero02.jpg'),
        require('../assets/hero03.jpg')
      ]
    }
  }
}
</script>

<style lang="scss">
.section1 {
  overflow-x: hidden;
  background-color: white;

  .yellow-container {
    background-color: #ffdd3b;
    height: 65px;
    span {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      letter-spacing: 0.5em;

      color: #003da2;
    }
  }
  .main-title {
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    color: #003da2;
  }
  .image-container {
    position: relative;
    height: 245px;
    img {
      position: absolute;
      right: -20px;
      width: 100%;
      height: 245px;
      opacity: 0;
      transition: opacity 0.4s ease-in-out;
    }
  }
  .container-white {
    height: 100%;
    padding: 20px 20px 20px 40px;
    position: relative;
    //opacity: 0.7;
    //filter: blur(2em);
    .white-background {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(239, 243, 251, 0.6);
      backdrop-filter: blur(6px);
      z-index: 10 !important;
    }
    span {
      position: absolute;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      color: #000000;
      opacity: 1 !important;
      z-index: 1000 !important;
    }
  }
  .button-arrow {
    background: #003da2;
    width: 40px;
    height: 40px;
  }
}

@keyframes moving {
  0% {
    bottom: 0;
  }
  50% {
    bottom: 20px;
  }
  100% {
    bottom: 0;
  }
}

@media (min-width: 960px) {
  .section1 {
    overflow-x: hidden;
    min-height: 87vh;
    background-color: transparent;
    position: relative;
    .yellow-container {
      height: 100px;
    }
    .scrollDown {
      position: absolute;
      bottom: 0;
      left: 50px;

      animation-name: moving !important;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }

    .main-title {
      font-weight: 800;
      font-size: 64px;
      line-height: 80px;
      position: absolute;
      opacity: 1 !important;
      width: 816px;
      height: 240px;
      left: 20%;
      top: 100px;
      z-index: 1000;
    }
    .image-container {
      height: 625px;
      img {
        width: 75%;
        right: 0;
        height: 625px;
      }
    }
    .container-white {
      //background-color: rgba(255, 255, 255, 0.7);

      height: 100%;
      position: relative;
      display: flex;
      align-content: flex-end;
      align-items: flex-end;
      
      span {
        font-size: 14px;
        line-height: 32px;
        color: #000000;
        
        opacity: 1 !important;
        display: inline-block;
        width: 360px;
        z-index: 1000 !important;
      }
    }
    .button-arrow {
      width: 80px;
      height: 80px;
      i {
        font-size: 40px;
      }
    }
  }
}
</style>
